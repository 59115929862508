<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card style="margin-top: 15px;">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">系统账户信息管理</span>
                </el-col>
            </el-row>
            <div class="row">
                <div class="col" v-for="item in menus" :key="item.id">
                    <div
                        class="system-btn"
                        style="margin-top: 10px"
                        @click="handleTo(item.path)"
                    >
                        <i class="icon iconfont" :class="item.icon"></i>
                        <div class="title">{{ item.title }}</div>
                        <div class="desc">{{ item.desc }}</div>
                    </div>
                </div>
            </div>
        </el-card>
        <el-card style="margin-top: 15px;">
            <el-row class="title_row">
                <el-col>
                    <span class="title_class">系统数据管理</span>
                </el-col>
            </el-row>
            <div class="row">
                <div class="col" v-for="item in dataMenus" :key="item.id">
                    <div
                        class="system-btn"
                        style="margin-top: 10px"
                        @click="handleTo(item.path)"
                    >
                        <i class="icon iconfont" :class="item.icon"></i>
                        <div class="title">{{ item.title }}</div>
                        <div class="desc">{{ item.desc }}</div>
                    </div>
                </div>
            </div>
        </el-card>
        <!-- 修改线上成绩的dialog -->
        <el-dialog
            title="修改订单时间"
            :visible.sync="editOrderTimeDialogVisible"
            @close="editOrderTimeDialogClose"
            width="30%"
        >
            <el-form
                :model="editTimeForm"
                label-width="80px"
                ref="editTimeFormRef"
                :rules="editTimeRules"
            >
                <el-form-item label="订单号" prop="code">
                    <el-input
                        v-model="editTimeForm.code"
                        placeholder="请输入"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editOrderTimeDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editTimeScore">修 改</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { updateEndDate } from '@/http/api'
export default {
  data () {
    return {
      editOrderTimeDialogVisible: false,
      editTimeRules: {
        code: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      editTimeForm: {
        code: ''
      },
      menus: [
        {
          id: 1,
          path: '/system_Management/system_management_user',
          title: '账号管理',
          desc: '新增、修改、查看ERP账号信息',
          icon: 'icon-user'
        },
        {
          id: 2,
          path: '/system_Management/system_management_section',
          title: '部门管理',
          desc: '新增、修改、查看部门信息',
          icon: 'icon-ziyuan'
        },
        {
          id: 3,
          path: '/system_Management/system_management_position',
          title: '岗位管理',
          desc: '新增、修改、查看岗位信息',
          icon: 'icon-zhiwei'
        },
        {
          id: 4,
          path: '/system_Management/system_management_authority',
          title: '权限管理',
          desc: '新增、修改、查看权限信息',
          icon: 'icon-quanxian'
        }
      ],
      dataMenus: [
        {
          id: 5,
          path: '/system_Management/system_management_dictionary',
          title: '字典管理',
          desc: '新增、修改、查看字典表信息',
          icon: 'icon-zidianguanli'
        },
        {
          id: 6,
          path: '/system_Management/system_management_logs',
          title: '日志管理',
          desc: '查看系统操作日志',
          icon: 'icon-rizhi'
        },
        {
          id: 7,
          path: '/system_Management/system_management_banner',
          title: '轮播图管理',
          desc: '小程序轮播图管理',
          icon: 'icon-haibaosheji'
        },
        {
          id: 8,
          path: '',
          title: '订单时间管理',
          desc: '修改小程序订单时间',
          icon: 'icon-dingdandingdanmingxishouzhimingxi'
        }
      ]
    }
  },
  created () {

  },
  methods: {
    handleTo (path) {
      if (path) {
        this.$router.push({ path })
      } else { // 修改订单时间
        this.editOrderTimeDialogVisible = true
      }
    },
    editOrderTimeDialogClose () {
      this.$refs.editTimeFormRef.resetFields()
    },
    editTimeScore () {
      this.$refs.editTimeFormRef.validate(valid => {
        if (!valid) return
        updateEndDate(this.editTimeForm).then(res => {
          this.$message.success('修改成功')
          this.editOrderTimeDialogVisible = false
        })
      })
    }
  }
}
</script>
  <style scoped lang="less">
.system-btn {
    border: 1px solid #0277ff;
    border-radius: 8px;
    background-color: #f4f5fc;
    text-align: center;
    padding: 20px 0;
    margin-right: 20px;
    width: 200px;
    cursor: pointer;
    &:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .icon {
        color: #0277ff;
        font-size: 30px;
    }
    .title {
        color: #0277ff;
        font-size: 16px;
        margin-top: 6px;
        margin-bottom: 6px;
    }
    .desc {
        font-size: 12px;
        color: #7b7b82;
    }
}
.row {
    overflow: hidden;
}
.col {
    float: left;
}
</style>
